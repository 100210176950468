import React from 'react'
import Message from '../../static/images/message.png'

export default function news() {
  return (
    <div>
      <section class="py-24 bg-black overflow-hidden">
  <div class="container mx-auto px-4">
    <div class="max-w-lg mx-auto text-center mb-14">
      <img class="mx-auto mb-8" src={Message} alt=""/>
      <h2 class="mb-5 font-heading font-semibold text-6xl sm:text-7xl text-white">Join Our Newsletter</h2>
      <p class="text-gray-400 text-lg">Adding simple buttons like the ones in this great signup form example by Videdressing makes your life easier and your email marketing more personalized. It lets you learn more about subscribers right from the signup form </p>
    </div>
    <div class="flex flex-wrap max-w-xl mx-auto -m-2.5">
      <div class="w-full p-2.5">
        <input class="w-full px-5 py-4 text-gray-500 text-base bg-transparent border border-gray-800 focus:border-gray-700 outline-none focus:ring-4 focus:ring-indigo-500 placeholder-white rounded" type="text" placeholder="Your name"/>
      </div>
      <div class="w-full p-2.5">
        <input class="w-full px-5 py-4 text-gray-500 text-base bg-transparent border border-gray-800 focus:border-gray-700 outline-none focus:ring-4 focus:ring-indigo-500 placeholder-white rounded" type="text" placeholder="Your email address"/>
      </div>
      <div class="w-full p-2.5">
        <div class="group relative">
          <div class="absolute top-0 left-0 w-full h-full bg-gradient-green opacity-0 group-hover:opacity-50 rounded transition ease-out duration-300"></div>
          <button class="p-1 w-full font-heading font-semibold text-xs text-gray-900 uppercase tracking-px overflow-hidden rounded">
            <div class="relative p-5 px-11 bg-gradient-green overflow-hidden rounded">
              <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full bg-white transition ease-in-out duration-500"></div>
              <p class="relative z-10">Subscribe now</p>
            </div>
          </button>
        </div>
      </div>
      <div class="w-full p-2.5">
        <p class="text-sm text-gray-300 text-center">We never share your information to any third party</p>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
