import React from 'react'
import Dashboard from '../../static/images/dashboard.jpg'

export default function works() {
  return (
    <div>
      <section class="relative pt-20 bg-black overflow-hidden">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap -m-6 mb-12">
      <div class="w-full p-6">
        <h2 class="mb-16 max-w-xl font-heading font-bold text-5xl sm:text-4xl text-white">Three steps to get started</h2>
        <div class="flex flex-wrap -m-6">
          <div class="w-full lg:w-1/2 p-6">
            <div class="mb-11 lg:max-w-sm">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="p-px max-w-max bg-gradient-cyan2 rounded-full">
                    <div class="flex justify-center items-center font-heading text-xl bg-gray-900 w-12 h-12 text-white rounded-full">1</div>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <h3 class="mb-4 font-heading font-medium text-xl text-white">Create A Free Account</h3>
                  <p class="text-gray-400 text-base">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                </div>
              </div>
            </div>
            <div class="mb-11 lg:max-w-sm">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="p-px max-w-max bg-gradient-cyan2 rounded-full">
                    <div class="flex justify-center items-center font-heading text-xl bg-gray-900 w-12 h-12 text-white rounded-full">2</div>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <h3 class="mb-4 font-heading font-medium text-xl text-white">Select Your Package</h3>
                  <p class="text-gray-400 text-base">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                </div>
              </div>
            </div>
            <div class="mb-11 lg:max-w-sm">
              <div class="flex flex-wrap -m-4">
                <div class="w-auto p-4">
                  <div class="p-px max-w-max bg-gradient-cyan2 rounded-full">
                    <div class="flex justify-center items-center font-heading text-xl bg-gray-900 w-12 h-12 text-white rounded-full">3</div>
                  </div>
                </div>
                <div class="flex-1 p-4">
                  <h3 class="mb-4 font-heading font-medium text-xl text-white">Grow Business Fast</h3>
                  <p class="mb-11 text-gray-400 text-base">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                  <div class="group relative max-w-max">
                    <div class="absolute top-0 left-0 w-full h-full bg-gradient-green opacity-0 group-hover:opacity-50 rounded-lg transition ease-out duration-300"></div>
                    <button class="p-1 w-full font-heading font-semibold text-xs text-gray-900 uppercase tracking-px overflow-hidden rounded-md">
                      <div class="relative p-4 px-11 bg-gradient-green overflow-hidden rounded-md">
                        <div class="absolute top-0 left-0 transform -translate-y-full group-hover:-translate-y-0 h-full w-full bg-white transition ease-in-out duration-500"></div>
                        <p class="relative z-10">Get Started now</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pl-4 lg:absolute lg:bottom-0 lg:right-0 lg:w-1/2">
    <div class="relative max-w-max ml-auto">
      <img class="relative z-10 ml-auto" src={Dashboard} alt=""/>
      <div class="absolute w-full -top-16 left-32 bg-gradient-cyan h-16 rounded-tl-3xl"></div>
    </div>
  </div>
</section>
    </div>
  )
}
