import React from 'react'
import Education from "../../static/images/Education.jpg"
import Education1 from "../../static/images/Education1.jpg"
import Education3 from "../../static/images/Education3.jpg"
import Logo1 from "../../static/images/logo1.png"
import Education5 from "../../static/images/Education5.jpg"
import Education4 from "../../static/images/Education4.jpg"


export default function clouds() {
  return (
    <div>
      <section class="pb-24 pt-20 bg-black overflow-hidden">
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap items-center -mx-4">
      <div class="w-full lg:w-2/5 px-4 mb-20 lg:mb-0">
        <div class="lg:max-w-lg">
          <span class="text-lg text-blue-400 font-bold">CLOUDS</span>
          <h2 class="mt-8 mb-12 text-6xl 2xl:text-7xl font-bold font-heading text-white">Trusted by brands all over the world</h2>
          <p class="text-lg text-gray-200">The brown fox jumps over the lazy dog.</p>
        </div>
      </div>
      <div class="w-full lg:w-3/5 px-4">
        <div class="flex justify-center items-center">
          <div class="py-8 px-2 sm:px-6 mr-3 bg-black">
            <img class=" h-28 mx-auto rounded-lg" src={Education} alt=""/>
          </div>
          <div class="flex flex-col mr-3">
            <div class="inline-block ml-auto py-12 px-4 sm:px-8 mb-3 bg-black">
              <img class="h-28 mx-auto rounded-lg" src={Education1} alt=""/>
            </div>
            <div class="py-16 px-2 sm:px-12 bg-gray-600 rounded-lg">
              <img class=" h-28 md:h-8 mx-auto" src={Logo1} alt=""/>
            </div>
          </div>
          <div class="-mb-32">
            <div class="py-8 px-2 sm:px-14 mb-3 bg-black">
              <img class=" h-40 mx-auto rounded-lg" src={Education4} alt=""/>
            </div>
            <div class="inline-block py-14 px-2 sm:px-8 bg-black">
              <img class="h-28 mx-auto" src={Education5} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
