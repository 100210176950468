import React from 'react'
import Contact from '../components/contact'
import Works from '../components/works'
import News from '../components/news'
import Clouds from '../components/clouds'
import Navbar from '../components/navbar'

export default function pricing() {
  return (
    <div>
        <Navbar/>
        <Clouds/>
        <News/>
        <Works/>
        <Contact/>

    </div>
  )
}
